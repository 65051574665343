import {
  Client,
  EndUserInfoRequest,
  Order,
  OrderRequest,
  ShippingPlace,
} from "@today/api/taker"
import { PhysicalAttributes } from "@today/api/common"
import dayjs from "dayjs"
import BaseBinder, {
  Column,
  Group,
  KeyMerger,
  Merger,
  throwErr,
  ValidationResult,
} from "./common"

type YesOrNo = "Y" | "N"

export type Row = {
  clientOrderId: string
  clientShippingId: string
  productCustomerRequestTime: string
  pickingLocation: string
  senderShippingPlaceName: string
  senderName: string
  senderPhone: string
  senderPostalCode: string
  senderAddress: string
  senderAccessMethod: string
  senderPreference: string
  receiverShippingPlaceName: string
  receiverName: string
  receiverPhone: string
  receiverPostalCode: string
  receiverAddress: string
  receiverAccessMethod: string
  receiverPreference: string
  sellerName: string
  channel: string
  productName: string
  productCount: string
  productCategory: string
  productFragile: YesOrNo
  productPrice: string
  productCode: string
  isReturning: YesOrNo
  originalInvoiceNumber: string
  boxSize: string
  fresh: string
}

export default class Default extends BaseBinder<Row> {
  readonly _columns: Column<Row>[] = [
    { label: "고객 주문번호", key: "clientOrderId" },
    { label: "고객 배송번호", key: "clientShippingId", optional: true },
    { label: "고객 주문일시", key: "productCustomerRequestTime" },
    { label: "피킹 위치", key: "pickingLocation" },
    { label: "보낸이 출고지", key: "senderShippingPlaceName" },
    { label: "보낸이 이름", key: "senderName" },
    { label: "보낸이 우편번호", key: "senderPostalCode", optional: true },
    { label: "보낸이 전화번호", key: "senderPhone" },
    { label: ["보낸이 주소", "픽업지 주소"], key: "senderAddress" },
    {
      label: ["보낸이 건물 출입방법", "픽업지 건물 출입방법"],
      key: "senderAccessMethod",
    },
    {
      label: ["보낸이 요청사항", "픽업지 출입 요청사항"],
      key: "senderPreference",
    },
    { label: "받는이 출고지", key: "receiverShippingPlaceName" },
    { label: "받는이 이름", key: "receiverName" },
    { label: "받는이 우편번호", key: "receiverPostalCode", optional: true },
    { label: "받는이 전화번호", key: "receiverPhone" },
    { label: "받는이 주소", key: "receiverAddress" },
    { label: "받는이 건물 출입방법", key: "receiverAccessMethod" },
    { label: "받는이 요청사항", key: "receiverPreference" },
    { label: "셀러명", key: "sellerName" },
    { label: "판매채널", key: "channel" },
    { label: "상품명", key: "productName" },
    { label: "상품 수량", key: "productCount" },
    { label: "크기타입", key: "boxSize", optional: true },
    { label: "아이스박스", key: "fresh", optional: true },
    { label: "상품 카테고리", key: "productCategory" },
    { label: "상품 깨짐주의", key: "productFragile" },
    { label: "상품 가액", key: "productPrice" },
    { label: "상품 코드", key: "productCode" },
    { label: "반품 여부", key: "isReturning", optional: true },
    { label: "반품 원송장번호", key: "originalInvoiceNumber" },
  ]

  protected overrideOptionalColumns: Column<Row>["key"][] = []

  get columns(): Column<Row>[] {
    return this._columns.map((c) => ({
      ...c,
      optional: c.optional || this.overrideOptionalColumns.includes(c.key),
    }))
  }

  readonly merger: Merger<Row> = new KeyMerger(["clientOrderId"])
  readonly shouldSupplyShippingPlace: boolean = false

  protected overrideRow(row: Row, index: number) {}

  protected validateRow(
    row: Row,
    index: number,
    allowedShippingTypes?: Order["shippingType"][]
  ): ValidationResult {
    this.overrideRow(row, index)
    const throwRowErr = (msg: string) => throwErr(index + 1, msg)
    if (row.productCustomerRequestTime) {
      const t = dayjs(row.productCustomerRequestTime)
      if (!t.isValid() || t.year() >= 2100)
        throwRowErr("고객 주문일시가 올바르지 않은 날짜 형식입니다.")
    }
    if (!row.senderName) throwRowErr("보낸이 이름이 입력되지 않았습니다.")
    if (!row.senderPhone) throwRowErr("보낸이 전화번호가 입력되지 않았습니다.")
    if (!row.senderAddress) throwRowErr("보낸이 주소가 입력되지 않았습니다.")
    if (!row.receiverName) throwRowErr("받는이 이름이 입력되지 않았습니다.")
    if (!row.receiverPhone)
      throwRowErr("받는이 전화번호가 입력되지 않았습니다.")
    if (!row.receiverAddress) throwRowErr("받는이 주소가 입력되지 않았습니다.")
    if (!row.productName) throwRowErr("상품명이 입력되지 않았습니다.")
    if (isNaN(parseInt(row.productCount)))
      throwRowErr("상품 수량이 숫자 형태가 아닙니다.")
    if (parseInt(row.productCount) <= 0)
      throwRowErr("상품 수량은 1 이상의 값이어야 합니다.")

    // 운송 타입별 특성 계산
    if (!allowedShippingTypes) {
      return {
        errors: [],
        warnings: [],
      }
    }
    let shouldSenderShippingPlaceExist =
      (allowedShippingTypes.includes("STATION_TO_STATION") ||
        allowedShippingTypes.includes("STATION_TO_LM")) &&
      !allowedShippingTypes.includes("LM_TO_STATION") &&
      !allowedShippingTypes.includes("LM_TO_LM")
    let shouldReceiverShippingPlaceExist =
      (allowedShippingTypes.includes("STATION_TO_STATION") ||
        allowedShippingTypes.includes("LM_TO_STATION")) &&
      !allowedShippingTypes.includes("STATION_TO_LM") &&
      !allowedShippingTypes.includes("LM_TO_LM")
    if (row.originalInvoiceNumber) {
      ;[shouldSenderShippingPlaceExist, shouldReceiverShippingPlaceExist] = [
        shouldReceiverShippingPlaceExist,
        shouldSenderShippingPlaceExist,
      ]
    }

    // 출고지 검증
    if (shouldSenderShippingPlaceExist && !row.senderShippingPlaceName) {
      throwRowErr("보낸이 출고지를 설정해야 합니다.")
    }
    // if (shouldReceiverShippingPlaceExist && !row.receiverShippingPlaceName) {
    //   throwRowErr("받는이 출고지를 설정해야 합니다.")
    // }
    if (
      allowedShippingTypes.includes("STATION_TO_STATION") &&
      row.senderShippingPlaceName &&
      row.receiverShippingPlaceName &&
      row.senderShippingPlaceName === row.receiverShippingPlaceName
    ) {
      throwRowErr("두 출고지가 같을 수 없습니다.")
    }
    if (
      !allowedShippingTypes.includes("STATION_TO_STATION") &&
      row.senderShippingPlaceName &&
      row.receiverShippingPlaceName
    ) {
      throwRowErr("출고지 두 개를 동시에 설정할 수 없습니다.")
    }
    // if (
    //   !allowedShippingTypes.includes("LM_TO_LM") &&
    //   !row.senderShippingPlaceName &&
    //   !row.receiverShippingPlaceName
    // ) {
    //   throwRowErr("출고지를 설정해야 합니다.")
    // }
    if (
      allowedShippingTypes.length === 1 &&
      allowedShippingTypes[0] === "LM_TO_LM" &&
      (row.senderShippingPlaceName || row.receiverShippingPlaceName)
    ) {
      throwRowErr("출고지를 설정할 수 없습니다.")
    }
    return {
      errors: [],
      warnings: [],
    }
  }

  private checkGroupColumns(
    group: Group<Row>,
    column: keyof Row,
    optional = false
  ) {
    group.rows
      .map((g) => g[column])
      .reduce((prev, v, i) => {
        if (optional && !v) return prev
        if (optional && !prev) return v
        if (prev !== v)
          throwErr(
            group.indices[i] + 1,
            `합포장 오류: ${group.indices[0] + 1}번째 줄과 ${(() => {
              const label = this.columns.find((c) => c.key === column)!.label
              return Array.isArray(label) ? label[0] : label
            })()} 값이 일치하지 않습니다.`
          )
        return v
      })
  }

  protected validateGroup(group: Group<Row>): ValidationResult {
    if (!group.rows.length) throw new Error("no rows in merged group")
    this.checkGroupColumns(group, "senderShippingPlaceName")
    this.checkGroupColumns(group, "senderName")
    this.checkGroupColumns(group, "senderAddress")
    this.checkGroupColumns(group, "senderPhone")
    this.checkGroupColumns(group, "receiverShippingPlaceName")
    this.checkGroupColumns(group, "receiverName")
    this.checkGroupColumns(group, "receiverAddress")
    this.checkGroupColumns(group, "receiverPhone")
    this.checkGroupColumns(group, "clientOrderId")
    this.checkGroupColumns(group, "originalInvoiceNumber")
    return {
      errors: [],
      warnings: [],
    }
  }

  protected useSkipTakeOut(): boolean {
    return false
  }

  protected convertGroupToRequest(
    group: Group<Row>,
    client: Client,
    shippingPlaces?: ShippingPlace | ShippingPlace[],
    sellerName?: string,
    senderAddress?: string
  ): OrderRequest {
    function find(key: keyof Row) {
      return group.rows.map((g) => g[key]).find((e) => !!e)
    }

    // 출고지명을 출고지 ID로 변환
    const senderShippingPlaceName = find("senderShippingPlaceName")
    const senderShippingPlace = Array.isArray(shippingPlaces)
      ? senderShippingPlaceName
        ? isUUIDv4(senderShippingPlaceName)
          ? shippingPlaces.find((sp) => sp.id === senderShippingPlaceName) ??
            throwErr(
              group.indices[0],
              `등록되지 않은 출고지입니다: ${senderShippingPlaceName}`
            )
          : shippingPlaces.find((sp) => sp.name === senderShippingPlaceName) ??
            throwErr(
              group.indices[0],
              `등록되지 않은 출고지입니다: ${senderShippingPlaceName}`
            )
        : undefined
      : shippingPlaces
    const receiverShippingPlaceName = find("receiverShippingPlaceName")
    const receiverShippingPlace = Array.isArray(shippingPlaces)
      ? receiverShippingPlaceName
        ? isUUIDv4(receiverShippingPlaceName)
          ? shippingPlaces.find((sp) => sp.id === receiverShippingPlaceName) ??
            throwErr(
              group.indices[0],
              `등록되지 않은 출고지입니다: ${senderShippingPlaceName}`
            )
          : shippingPlaces.find(
              (sp) => sp.name === receiverShippingPlaceName
            ) ??
            throwErr(
              group.indices[0],
              `등록되지 않은 출고지입니다: ${senderShippingPlaceName}`
            )
        : undefined
      : undefined

    const sender: EndUserInfoRequest = {
      ...(senderShippingPlace
        ? { shippingPlaceId: senderShippingPlace.id }
        : {}),
      name: find("senderName") || client.name,
      phone: find("senderPhone") || client.phone || "-",
      address:
        find("senderAddress") ||
        senderAddress ||
        (senderShippingPlace
          ? `${senderShippingPlace.addressInfo.streetBaseAddress} ${senderShippingPlace.addressInfo.streetDetailAddress}`
          : ""),
      accessMethod: find("senderAccessMethod"),
      preference: find("senderPreference"),
    }
    const receiver: EndUserInfoRequest = {
      ...(receiverShippingPlace
        ? { shippingPlaceId: receiverShippingPlace.id }
        : {}),
      name: find("receiverName")!,
      phone: find("receiverPhone")!,
      address: find("receiverAddress")!,
      accessMethod: find("receiverAccessMethod"),
      preference: find("receiverPreference"),
    }
    return {
      clientOrderId: find("clientOrderId"),
      clientShippingId: find("clientShippingId"),
      sender,
      receiver,
      physicalAttributes:
        find("boxSize") || find("fresh")
          ? {
              size: (find("boxSize") ??
                undefined) as PhysicalAttributes["size"],
              fresh: find("fresh") === "V" || find("fresh") === "v" || false,
            }
          : undefined,
      products: group.rows.flatMap((row) =>
        row.productName
          .split(";")
          .filter((name) => name)
          .map((name) => ({
            name,
            price: parseInt(row.productPrice ?? "0"),
            sellerName: find("sellerName") ?? sellerName,
            clientProductId: row.productCode.toString(),
            count: +row.productCount,
            fragile: row.productFragile === "Y",
            pickingLocation: row.pickingLocation,
            ...(row.productCustomerRequestTime
              ? {
                  customerRequestTime: dayjs(
                    row.productCustomerRequestTime
                  ).format(),
                }
              : {}),
          }))
      ),
      returningInfo: find("originalInvoiceNumber")
        ? {
            invoiceNumber: find("originalInvoiceNumber")!.replace(/-/g, ""),
          }
        : null,
      developerPayload: JSON.stringify({
        "@today": {
          channel: find("channel"),
        },
      }),
    }
  }
}

function isUUIDv4(uuid: string) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    uuid
  )
}
