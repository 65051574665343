import { Order } from "@today/api/taker"
import { selectJosa } from "@today/lib"
import dayjs from "dayjs"
import {
  Column,
  Group,
  KeyMerger,
  Merger,
  NoMerger,
  ParseResult,
  ValidationResult,
} from "./common"
import Default from "./default"

type YesOrNo = "Y" | "N"

export type Row = {
  clientOrderId: string
  clientShippingId: string
  productCustomerRequestTime: string
  pickingLocation: string
  senderShippingPlaceName: string
  senderName: string
  senderPhone: string
  senderPostalCode: string
  senderAddress: string
  senderAccessMethod: string
  senderPreference: string
  receiverShippingPlaceName: string
  receiverName: string
  receiverPhone: string
  receiverPostalCode: string
  receiverAddress: string
  receiverAccessMethod: string
  receiverPreference: string
  sellerName: string
  channel: string
  productName: string
  productCount: string
  productCategory: string
  productFragile: YesOrNo
  productPrice: string
  productCode: string
  isReturning: YesOrNo
  originalInvoiceNumber: string
  boxSize: string
  fresh: string
}

const rowKeyToLabelCandidates: { [key in keyof Row]: string[] } = {
  clientOrderId: ["고객 주문번호", "주문번호", "묶음배송번호"],
  clientShippingId: ["고객 배송번호", "배송번호"],
  productCustomerRequestTime: [
    "고객 주문일시",
    "주문일시",
    "주문일자",
    "주문일",
  ],
  pickingLocation: ["피킹 위치"],
  senderShippingPlaceName: ["보낸이 출고지"],
  senderName: [
    "보낸이 이름",
    "구매자명",
    "구매자",
    "주문자명",
    "주문자",
    "구매자 이름",
    "주문자 이름",
    "발송자명",
  ],
  senderPhone: ["보낸이 전화번호", "구매자 연락처", "구매자 전화번호"],
  senderPostalCode: ["보낸이 우편번호"],
  senderAddress: ["보낸이 주소", "픽업지 주소", "출고지"],
  senderAccessMethod: ["보낸이 건물 출입방법"],
  senderPreference: ["보낸이 요청사항"],
  receiverShippingPlaceName: ["받는이 출고지"],
  receiverName: [
    "받는이 이름",
    "수취인명",
    "수취인 이름",
    "수령인명",
    "수령인 이름",
    "수령자",
    "고객명",
  ],
  receiverPhone: [
    "받는이 전화번호",
    "고객 전화번호",
    "수취인 연락처",
    "수취인 연락처1",
    "수취인 전화번호",
    "휴대전화",
    "휴대전화 번호",
    "휴대폰 번호",
  ],
  receiverPostalCode: ["받는이 우편번호", "우편번호"],
  receiverAddress: [
    "받는이 주소",
    "수취인 주소",
    "배송지",
    "통합배송지",
    "고객 주소",
  ],
  receiverAccessMethod: ["받는이 건물 출입방법", "고객 건물 출입방법"],
  receiverPreference: [
    "받는이 요청사항",
    "고객 요청사항",
    "배송시 요청사항",
    "배송 메세지",
    "배송 메시지",
    "상품별 추가메시지",
    "주문자 추가메시지",
  ],
  sellerName: ["셀러명"],
  channel: ["판매채널"],
  productName: ["상품명", "노출상품명", "노출상품명 (옵션명)", "판매사 상품명"],
  productCount: ["상품 수량", "수량", "구매수", "구매수 (수량)"],
  productCategory: ["상품 카테고리"],
  productFragile: ["상품 깨짐주의"],
  productPrice: [
    "상품가액",
    "상품가격",
    "상품별 총 주문금액",
    "결제액",
    "결제금액",
    "총결제금액",
  ],
  productCode: ["상품 코드", "상품 주문번호", "옵션 ID"],
  isReturning: ["반품 여부"],
  originalInvoiceNumber: ["반품 원송장번호"],
  boxSize: ["크기타입"],
  fresh: ["아이스박스"],
}

const labelToRowKey = reverseMapping(rowKeyToLabelCandidates)

export default class FlexibleBinder extends Default {
  readonly _columns: Column<Row>[] = [
    { label: "고객 주문번호", key: "clientOrderId", optional: true },
    { label: "고객 배송번호", key: "clientShippingId", optional: true },
    {
      label: "고객 주문일시",
      key: "productCustomerRequestTime",
      optional: true,
    },
    { label: "피킹 위치", key: "pickingLocation", optional: true },
    { label: "보낸이 출고지", key: "senderShippingPlaceName", optional: true },
    { label: "보낸이 이름", key: "senderName", optional: true },
    { label: "보낸이 우편번호", key: "senderPostalCode", optional: true },
    { label: "보낸이 전화번호", key: "senderPhone", optional: true },
    { label: "보낸이 주소", key: "senderAddress", optional: true },
    {
      label: "보낸이 건물 출입방법",
      key: "senderAccessMethod",
      optional: true,
    },
    { label: "보낸이 요청사항", key: "senderPreference", optional: true },
    {
      label: "받는이 출고지",
      key: "receiverShippingPlaceName",
      optional: true,
    },
    { label: "받는이 이름", key: "receiverName" },
    { label: "받는이 우편번호", key: "receiverPostalCode", optional: true },
    { label: "받는이 전화번호", key: "receiverPhone" },
    { label: "받는이 주소", key: "receiverAddress" },
    {
      label: "받는이 건물 출입방법",
      key: "receiverAccessMethod",
      optional: true,
    },
    { label: "받는이 요청사항", key: "receiverPreference", optional: true },
    { label: "셀러명", key: "sellerName", optional: true },
    { label: "판매채널", key: "channel", optional: true },
    { label: "상품명", key: "productName" },
    { label: "상품 수량", key: "productCount" },
    { label: "상품 카테고리", key: "productCategory", optional: true },
    { label: "상품 깨짐주의", key: "productFragile", optional: true },
    { label: "상품 가액", key: "productPrice" },
    { label: "상품 코드", key: "productCode" },
    { label: "반품 여부", key: "isReturning", optional: true },
    { label: "반품 원송장번호", key: "originalInvoiceNumber", optional: true },
  ]

  protected overrideOptionalColumns: Column<Row>["key"][] = []

  get columns(): Column<Row>[] {
    return this._columns.map((c) => ({
      ...c,
      optional: c.optional || this.overrideOptionalColumns.includes(c.key),
    }))
  }

  readonly merger: Merger<Row> = new KeyMerger(["clientOrderId"])
  readonly shouldSupplyShippingPlace: boolean = false

  parse(json: { [key: string]: string }[]): ParseResult<Row> {
    const rows: Row[] = []
    const warnings: Set<string> = new Set()
    const errors: Set<string> = new Set()
    for (const obj of json) {
      const row: Record<string, string> = {}
      const entries = Object.entries(obj)
      for (const [label, value] of entries) {
        const key = labelToRowKey[standardizeString(label)]
        if (!key) {
          warnings.add(
            `"${label}"${selectJosa(label, [
              "은",
              "는",
            ])} 투데이 시스템에 등록되지 않는 컬럼입니다.`
          )
          continue
        }
        if (row[key]) {
          const anotherLabel = entries.find(
            ([l, v]) => l !== label && v === row[key]
          )![0]
          warnings.add(
            `"${label}"${selectJosa(label, [
              "은",
              "는",
            ])} "${anotherLabel}"${selectJosa(anotherLabel, [
              "과",
              "와",
            ])} 중복된 컬럼입니다.`
          )
          continue
        }
        // 엑셀 파싱 결과 object가 될 수 있는 값은 Date 뿐이라고 가정
        if (typeof value === "object") {
          row[key] = dayjs(value).format("YYYY-MM-DD HH:mm:ss")
        } else {
          row[key] = value
        }
      }
      // 누락된 컬럼이 없는지 확인
      let hasMissingColumn = false
      for (const { label, key, optional } of this.columns) {
        if (!optional && row[key] === undefined) {
          errors.add(
            `"${label}"${selectJosa(label as string, [
              "은",
              "는",
            ])} 필수 컬럼입니다.\n다음 이름 중 하나가 필요합니다: ${rowKeyToLabelCandidates[
              key
            ].join(", ")}`
          )
          hasMissingColumn = true
        }
      }
      if (hasMissingColumn) {
        return {
          rows: [],
          errors: [...errors],
          warnings: [...warnings],
        }
      }
      rows.push(row as Row)
    }
    this.rows = rows
    return {
      rows,
      errors: [...errors],
      warnings: [...warnings],
    }
  }

  protected validateRow(
    row: Row,
    index: number,
    allowedShippingTypes?: Order["shippingType"][],
    checkSenderAddress?: boolean
  ): ValidationResult {
    this.overrideRow(row, index)
    const warnings: string[] = []
    const errors: string[] = []

    const pushError = (msg: string) =>
      errors.push(`${index + 1}번째 줄:\n${msg}`)
    if (row.productCustomerRequestTime) {
      const t = dayjs(row.productCustomerRequestTime)
      if (!t.isValid() || t.year() >= 2100)
        pushError("고객 주문일시가 올바르지 않은 날짜 형식입니다.")
    }
    if (checkSenderAddress && !row.senderAddress) {
      pushError("보낸이 주소가 입력되지 않았습니다.")
    }
    if (!row.receiverName) pushError("받는이 이름이 입력되지 않았습니다.")
    if (!row.receiverPhone) pushError("받는이 전화번호가 입력되지 않았습니다.")
    if (!row.receiverAddress) pushError("받는이 주소가 입력되지 않았습니다.")
    if (!row.productName) pushError("상품명이 입력되지 않았습니다.")
    if (isNaN(parseInt(row.productCount)))
      pushError("상품 수량이 숫자 형태가 아닙니다.")
    if (parseInt(row.productCount) <= 0)
      pushError("상품 수량은 1 이상의 값이어야 합니다.")

    // 운송 타입별 특성 계산
    if (allowedShippingTypes) {
      let shouldSenderShippingPlaceExist =
        (allowedShippingTypes.includes("STATION_TO_STATION") ||
          allowedShippingTypes.includes("STATION_TO_LM")) &&
        !allowedShippingTypes.includes("LM_TO_STATION") &&
        !allowedShippingTypes.includes("LM_TO_LM")
      let shouldReceiverShippingPlaceExist =
        (allowedShippingTypes.includes("STATION_TO_STATION") ||
          allowedShippingTypes.includes("LM_TO_STATION")) &&
        !allowedShippingTypes.includes("STATION_TO_LM") &&
        !allowedShippingTypes.includes("LM_TO_LM")
      if (row.originalInvoiceNumber) {
        ;[shouldSenderShippingPlaceExist, shouldReceiverShippingPlaceExist] = [
          shouldReceiverShippingPlaceExist,
          shouldSenderShippingPlaceExist,
        ]
      }

      // 출고지 검증
      if (shouldSenderShippingPlaceExist && !row.senderShippingPlaceName) {
        pushError("보낸이 출고지를 설정해야 합니다.")
      }
      if (
        allowedShippingTypes.includes("STATION_TO_STATION") &&
        row.senderShippingPlaceName &&
        row.receiverShippingPlaceName &&
        row.senderShippingPlaceName === row.receiverShippingPlaceName
      ) {
        pushError("두 출고지가 같을 수 없습니다.")
      }
      if (
        !allowedShippingTypes.includes("STATION_TO_STATION") &&
        row.senderShippingPlaceName &&
        row.receiverShippingPlaceName
      ) {
        pushError("출고지 두 개를 동시에 설정할 수 없습니다.")
      }
      if (
        allowedShippingTypes.length === 1 &&
        allowedShippingTypes[0] === "LM_TO_LM" &&
        (row.senderShippingPlaceName || row.receiverShippingPlaceName)
      ) {
        pushError("출고지를 설정할 수 없습니다.")
      }
    }
    return {
      errors,
      warnings,
    }
  }

  private _checkGroupColumns(
    group: Group<Row>,
    column: keyof Row,
    optional = false
  ): ValidationResult {
    const errors: string[] = []
    group.rows
      .map((g) => g[column])
      .reduce((prev, v, i) => {
        if (optional && !v) return prev
        if (optional && !prev) return v
        if (prev !== v)
          errors.push(
            `${group.indices[i] + 1}번째 줄:\n합포장 오류: ${
              group.indices[0] + 1
            }번째 줄과 ${(() => {
              const label = this.columns.find((c) => c.key === column)!.label
              return Array.isArray(label) ? label[0] : label
            })()} 값이 일치하지 않습니다.`
          )
        return v
      })
    return {
      errors,
      warnings: [],
    }
  }

  protected validateGroup(group: Group<Row>): ValidationResult {
    if (!group.rows.length) {
      throw new Error("no rows in merged group")
    }
    const keys: (keyof Row)[] = [
      "senderShippingPlaceName",
      "senderName",
      "senderAddress",
      "senderPhone",
      "receiverShippingPlaceName",
      "receiverName",
      "receiverAddress",
      "receiverPhone",
      "clientOrderId",
      "originalInvoiceNumber",
    ]
    const errors = keys.flatMap((column) => {
      return this._checkGroupColumns(group, column).errors
    })
    return {
      errors,
      warnings: [],
    }
  }
}

function standardizeString(s: string) {
  return s.toLowerCase().replace(/\s+/g, "")
}

function reverseMapping(
  original: Record<string, string[]>
): Record<string, string> {
  const result: Record<string, string> = {}
  for (const [key, values] of Object.entries(original)) {
    for (const value of values) {
      const standardizedValue = standardizeString(value)
      result[standardizedValue] = key
    }
  }
  return result
}
